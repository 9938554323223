import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LVConfig from '../globals';

Vue.use(VueRouter)
let live_uri = LVConfig.live_uri;
let live_titles = LVConfig.live_titles;
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },


  {
    path: '/:slug',
    name: 'ArticleViewLoader',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "article" */ '../views/ArticleViewLoader.vue')
  },


  {
    path: '/uzivo/:slug',
    name: 'uzivo-lat',
    component: () => import(/* webpackChunkName: "videoPlaylist" */ '../views/LiveVideo.vue'),
    props: route => ({
      channel: {
        SideBar: false,
        Source: live_uri[route.params.slug],
        Width: "900",
        Height: "506",
        Title:live_titles[route.params.slug],
        hdvideoplayer:"hdVideoPlayer_"+ Math.random().toString(36).substr(2, 9)
      }
    })
  },
  {
    path: `/${encodeURIComponent("уживо")}/:slug`,
    alias: '/уживо/:slug',
    name: 'uzivo-cir',
    component: () => import(/* webpackChunkName: "videoPlaylist" */ '../views/LiveVideo.vue'),
    props: route => ({
      channel: {
        SideBar: false,
        Source: live_uri[decodeURIComponent(route.params.slug)],
        Width: "900",
        Height: "506",
        Title:live_titles[decodeURIComponent(route.params.slug)]
      }
    })
  },

  {
    path: '/emisije-tv/:slug',
    name: 'emisija-tv-lat',
    component: () => import(/* webpackChunkName: "videoPlaylist" */ '../views/VideoPlaylist.vue')
  },

  {
    path: '/емисије-тв/:slug',
    name: 'emisija-tv-cir',
    component: () => import(/* webpackChunkName: "videoPlaylist" */ '../views/VideoPlaylist.vue')
  },

  {
    path: `/${encodeURIComponent('емисије-тв')}/:slug`,
    name: 'emisija-tv-cir-encoded',
    component: () => import(/* webpackChunkName: "videoPlaylist" */ '../views/VideoPlaylist.vue')
  },

  {
    path: '/emisije-radio/:slug',
    name: 'emisija-radio-lat',
    component: () => import(/* webpackChunkName: "audioPlaylist" */ '../views/AudioPlaylist.vue')
  },

  {
    path: '/емисије-радио/:slug',
    name: 'emisija-radio-cir',
    component: () => import(/* webpackChunkName: "audioPlaylist" */ '../views/AudioPlaylist.vue')
  },

  {
    path: `/${encodeURIComponent('емисије-радио')}/:slug`,
    name: 'emisija-radio-cir-encoded',
    component: () => import(/* webpackChunkName: "audioPlaylist" */ '../views/AudioPlaylist.vue')
  },

  {
    path: '/emisije-radio/:playlist/:audio',
    name: 'emisija-radio-single-lat',
    component: () => import(/* webpackChunkName: "audioPage" */ '../views/AudioPage.vue')
  },

  {
    path: '/емисије-радио/:playlist/:audio',
    name: 'emisija-radio-single-cir',
    component: () => import(/* webpackChunkName: "audioPage" */ '../views/AudioPage.vue')
  },

  {
    path: `/${encodeURIComponent('емисије-радио')}/:playlist/:audio`,
    name: 'emisija-radio-single-cir-encoded',
    component: () => import(/* webpackChunkName: "audioPlaylist" */ '../views/AudioPage.vue')
  },

  {
    path: '*',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
