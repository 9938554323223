export default {
    data() {
        return {
            hamburgerNavItems: [
                {
                    title: {
                        lat: 'BHT1',
                        cir: 'БХТ1'
                    },

                    internal_url: {
                        lat: '/bht1',
                        cir: '/бхт1'
                    }
                },

                {
                    title: {
                        lat: 'BHR1',
                        cir: 'БХР1'
                    },

                    internal_url: {
                        lat: '/bhr1',
                        cir: '/бхр1'
                    }
                },

                {
                    title: {
                        lat: 'MP BHRT',
                        cir: 'МП БХРТ'
                    },

                    internal_url: {
                        lat: '/mp-bhrt',
                        cir: '/мп-бхрт'
                    }
                },

                {
                    title: {
                        lat: 'Društvo i religija',
                        cir: 'Друштво и религија'
                    },

                    internal_url: {
                        lat: '/drustvo-i-religija',
                        cir: '/друштво-и-религија'
                    }
                },

                {
                    title: {
                        lat: 'Video',
                        cir: 'Bидео'
                    },

                    internal_url: {
                        lat: '/video',
                        cir: '/видео'
                    }
                },


                {
                    title: {
                        lat: 'BHRT teme',
                        cir: 'БХРТ теме'
                    },

                    internal_url: {
                        lat: '/bhrt-teme',
                        cir: '/бхрт-теме'
                    }
                },

                {
                    title: {
                        lat: 'Oglasi',
                        cir: 'Огласи'
                    },

                    internal_url: {
                        lat: '/oglasi',
                        cir: '/огласи'
                    }
                },

                {
                    title: {
                        lat: 'Marketing',
                        cir: 'Маркетинг'
                    },

                    internal_url: {
                        lat: '/marketing',
                        cir: '/маркетинг'
                    }
                },

                {
                    title: {
                        lat: 'RTV taksa',
                        cir: 'РТВ такса'
                    },

                    internal_url: {
                        lat: '/rtv-taksa',
                        cir: '/ртв-такса'
                    }
                },

                {
                    title: {
                        lat: 'Kontakti',
                        cir: 'Контакти'
                    },

                    internal_url: {
                        lat: '/kontakti',
                        cir: '/контакти'
                    }
                },

                {
                    title: {
                        lat: 'Javne nabavke',
                        cir: 'Јавне набавке'
                    },

                    external_url: 'http://javnenabavke.bhrt.ba/?_gl=1*uaftm5*_ga*MTUzNTgxOTY1MS4xNjExMTM2OTY0*_ga_GTFVRM0KG7*MTYyNjg1ODI0Ny4yNC4xLjE2MjY4NjA3NDEuMzk.&_ga=2.117681034.2069584321.1626858251-1535819651.1611136964'
                },

                {
                    title: {
                        lat: 'TTX',
                        cir: 'TTX'
                    },

                    external_url: 'http://teletext.bhrt.ba/?_gl=1*13g6vv6*_ga*MTUzNTgxOTY1MS4xNjExMTM2OTY0*_ga_GTFVRM0KG7*MTYyNjg1ODI0Ny4yNC4xLjE2MjY4NjA3NDEuMzk.&_ga=2.80922772.2069584321.1626858251-1535819651.1611136964'
                },
            ]
        }
    }
}