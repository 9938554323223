<template>
  <div class="container marginTop">
    <div class="emisije">
      <div class="newsContTitle">
        {{ title[lang] }}
      </div>

      <div id="emisije_slider">
        <VueSlickCarousel v-if="getWidth() < 770" v-bind="settings">
          <article-video
              v-for="(article, key) in articles"
              :key="`${key}-article-video-mobile`"
              :article="article"
          />
        </VueSlickCarousel>

        <article-video
            v-else
            v-for="(article, key) in articles"
            :key="`${key}-article-video-desktop`"
            :article="article"
        />
      </div>
    </div>

    <span class="clearfix"></span>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import ArticleVideo from "./ArticleVideo";
  import SlickMixin from "../mixins/SlickMixin";

  export default {
    components: {
      ArticleVideo
    },

    mixins: [
        LangMixin,
        SlickMixin
    ],

    props: {
      articles: {
        type: Array,
        required: true
      },

      title: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          speed: 1000,
          autoplay: true,
          autoplaySpeed: 3500,
          responsive: [
            {
              breakpoint: 740,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }]
        }
      }
    }
  }
</script>