export default {
    data() {
        return {
            categories: [
                {
                    lat: {
                        title: 'BiH',
                        slug: 'vijesti-bih',
                        api_param: 'bih'
                    },

                    cir: {
                        title: 'БиХ',
                        slug: 'вијести-бих',
                        api_param: 'бих'
                    }
                },

                {
                    lat: {
                        title: 'Region',
                        slug: 'vijesti-region',
                        api_param: 'region'
                    },

                    cir: {
                        title: 'Регион',
                        slug: 'вијести-регион',
                        api_param: 'регион'
                    }
                },

                {
                    lat: {
                        title: 'Svijet',
                        slug: 'vijesti-svijet',
                        api_param: 'svijet'
                    },

                    cir: {
                        title: 'Свијет',
                        slug: 'вијести-свијет',
                        api_param: 'свијет'
                    }
                },
                {
                    lat: {
                        title: 'Promo',
                        slug: 'promo',
                        api_param: 'promo'
                    },

                    cir: {
                        title: 'Промо',
                        slug: 'промо',
                        api_param: 'промо'
                    }
                },
                {
                    lat: {
                        title: 'Qatar 2022',
                        slug: 'qatar-2022',
                        api_param: 'qatar 2022'
                    },

                    cir: {
                        title: 'Катар 2022',
                        slug: 'катар-2022',
                        api_param: 'катар 2022'
                    }
                },{
                    lat: {
                        title: 'Aktuelno BHRT',
                        slug: 'aktuelno-bhrt',
                        api_param: 'aktuelno bhrt'
                    },

                    cir: {
                        title: 'Актуелно БХРТ',
                        slug: 'актуелно-бхрт',
                        api_param: 'актуелно бхрт'
                    }
                },
                {
                    lat: {
                        title: 'Izbori',
                        slug: 'izbori',
                        api_param: 'izbori'
                    },

                    cir: {
                        title: 'Избори',
                        slug: 'избори',
                        api_param: 'избори'
                    }
                },
                {
                    lat: {
                        title: 'EURO 2024',
                        slug: 'euro-2024',
                        api_param: 'euro 2024'
                    },

                    cir: {
                        title: 'ЕУРО 2024',
                        slug: 'еуро-2024',
                        api_param: 'еуро 2024'
                    }
                },
                {
                    lat: {
                        title: 'MESS',
                        slug: 'mess',
                        api_param: 'mess'
                    },

                    cir: {
                        title: 'МЕСС',
                        slug: 'месс',
                        api_param: 'месс'
                    }
                },
                {
                    lat: {
                        title: 'Sarajevo film festival',
                        slug: 'sff',
                        api_param: 'sff'
                    },

                    cir: {
                        title: 'Сарајево филм фестивал',
                        slug: 'сфф',
                        api_param: 'сфф'
                    }
                },
                {
                    lat: {
                        title: 'Beijing 2022',
                        slug: 'beijing-2022',
                        api_param: 'beijing 2022'
                    },

                    cir: {
                        title: 'Беијинг 2022',
                        slug: 'беијинг-2022',
                        api_param: 'беијинг 2022'
                    }
                },
                {
                    lat: {
                        title: 'Olimpijske igre',
                        slug: 'olimpijske-igre',
                        api_param: 'olimpijske igre'
                    },

                    cir: {
                        title: 'Олимпијске игре',
                        slug: 'олимпијске-игре',
                        api_param: 'олимпијске игре'
                    }
                },
                {
                    lat: {
                        title: 'Izbor urednika',
                        slug: 'izbor-urednika',
                        api_param: 'izbor urednika'
                    },

                    cir: {
                        title: 'Избор уредника',
                        slug: 'избор-уредника',
                        api_param: 'избор уредника'
                    }
                },
                {
                    lat: {
                        title: 'Ekonomija',
                        slug: 'ekonomija',
                        api_param: 'ekonomija'
                    },

                    cir: {
                        title: 'Економија',
                        slug: 'економија',
                        api_param: 'економија'
                    }
                },

                {
                    lat: {
                        title: 'Sport',
                        slug: 'sport',
                        api_param: 'sport'
                    },

                    cir: {
                        title: 'Спорт',
                        slug: 'спорт',
                        api_param: 'спорт'
                    }
                },

                {
                    lat: {
                        title: 'Kultura',
                        slug: 'kultura',
                        api_param: 'kultura'
                    },

                    cir: {
                        title: 'Култура',
                        slug: 'култура',
                        api_param: 'култура'
                    }
                },

                {
                    lat: {
                        title: 'Mozaik',
                        slug: 'mozaik',
                        api_param: 'mozaik'
                    },

                    cir: {
                        title: 'Мозаик',
                        slug: 'мозаик',
                        api_param: 'мозаик'
                    }
                },

                {
                    lat: {
                        title: 'Društvo i religija',
                        slug: 'drustvo-i-religija',
                        api_param: 'religija'
                    },

                    cir: {
                        title: 'Друштво и религија',
                        slug: 'друштво-и-религија',
                        api_param: 'религија'
                    }
                },

                {
                    lat: {
                        title: 'Video',
                        slug: 'video',
                        api_param: 'video'
                    },

                    cir: {
                        title: 'Bидео',
                        slug: 'видео',
                        api_param: 'видео'
                    }
                },

                {
                    lat: {
                        title: 'BHRT teme',
                        slug: 'bhrt-teme',
                        api_param: 'bhrt teme'
                    },

                    cir: {
                        title: 'БХРТ теме',
                        slug: 'бхрт-теме',
                        api_param: 'бхрт теме'
                    }
                },
            ]
        }
    },

    methods: {
        findArticleCategory(slug) {
            return this.categories.find(category => category.lat.slug === slug || category.cir.slug === slug);
        },
    }
}