<template>
  <div id="footer_link_cont">
    <div
        v-for="(footerNavItemSection, footerNavItemSectionKey) in footerNavItems"
        :key="footerNavItemSectionKey"
        class="footer_link_cont"
    >
      <div v-for="(footerNavItem, footerNavItemKey) in footerNavItemSection" :key="footerNavItemKey">
        <nav-item
            :nav-item="footerNavItem"
            :id="footerNavItem.hasOwnProperty('id') ? footerNavItem.id : null"
        >
          <template v-slot="{ title }">
            <b>
              {{ title }}
            </b>
          </template>
        </nav-item>

        <div v-if="footerNavItem.hasOwnProperty('subItems')">
          <nav-item
              v-for="(footerSubItem, footerSubItemKey) in footerNavItem.subItems"
              :key="footerSubItemKey"
              :nav-item="footerSubItem"
          >
            <template v-slot="{ title }">
              … {{ title }}
            </template>
          </nav-item>
        </div>
      </div>

    </div>

    <a href="" title="" id="footer_weather">
      <img src="../assets/images/footer_weather.svg" />
    </a>
  </div>
</template>

<script>
  import FooterMixin from "../mixins/nav/FooterMixin";
  import NavItem from "../components/NavItem";

  export default {
    mixins: [
        FooterMixin,
    ],

    components: {
      NavItem
    }
  }
</script>