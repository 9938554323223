export default {
    data() {
        return {
            page: {},
        }
    },

    methods: {
        async requestPageData(path) {
            this.$emit('pageLoading');

            return fetch(`${this.$bhrt.api_uri}/${path}`)
                .then(response => {
                    this.$emit('pageLoaded')
                    return response.json()
                })
                .then(data => this.page = data)
                .catch(error => console.log(error));
        }
    }
}