export default {
    data() {
        return {
            settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                speed: 1000,
                autoplay: true,
                autoplaySpeed: 3500
            }
        }
    }
}