<template>
  <div v-if="Object.keys(page).length">
    <main-section
        :top3vijesti="page.top3vijesti.data"
        :novo="page.top4vijesti.data"
        :popularno="page.popular.data"
        :promo="page.promo.data"
    />

    <weather-section :forcast-cities="page.vrijeme.Forecast"/>

    <vertical-articles-container>
      <vertical-articles :articles="page.bih.data" :limit="6" :title="sections.bih"/>
      <vertical-articles :articles="page.region.data" :limit="6" :title="sections.region"/>
      <vertical-articles :articles="page.svijet.data" :limit="6" :title="sections.svijet"/>
    </vertical-articles-container>

    <adv :ad="ads[11]"/>

    <article-videos :articles="page.emisije.data.items" :title="sections.emisije"/>

    <horizontal-articles
        :articles="page.sport.data"
        :title="sections.sport"
        :show-smal-boxes="true"
    />

    <vertical-articles-container>
      <vertical-articles
          :articles="page.kultura.data"
          :limit="5"
          :title="sections.kultura"
          :title-badge-big="true"
          :article-container-small="true"
      />

      <vertical-articles
          :articles="page.video.data"
          :limit="5"
          :title="sections.video"
          :title-badge-big="true"
          :article-container-small="true"
      />

      <vertical-articles
          :articles="page.mozaik.data"
          :limit="5"
          :title="sections.mozaik"
          :title-badge-big="true"
          :article-container-small="true"
      />
    </vertical-articles-container>

    <adv :ad="ads[12]"/>

    <horizontal-articles
        :articles="page.bhrtteme.data"
        :title="sections.bhrtteme"
        :show-smal-boxes="true"
    />

    <adv :ad="ads[13]"/>

    <horizontal-articles
        :articles="page.ekonomija.data"
        :title="sections.ekonomija"
    />

    <horizontal-articles
        :articles="page.dir.data"
        :title="sections.dir"
    />

    <adv :ad="ads[14]"/>
  </div>
</template>

<script>
// @ is an alias to /src

  import PageMixin from "../mixins/PageMixin";
  import MainSection from "../partials/home/MainSection";
  import WeatherSection from "../partials/home/WeatherSection";
  import eventBus from "../eventBus";
  import HorizontalArticles from "../components/HorizontalArticles";
  import VerticalArticlesContainer from "../components/VerticalArticlesContainer";
  import VerticalArticles from "../components/VerticalArticles";
  import ArticleVideos from "../components/ArticleVideos";
  import AdvMixin from "../mixins/AdvMixin";
  import Adv from "../components/Adv";

  export default {
    name: 'Home',

    mixins: [
        PageMixin,
        AdvMixin
    ],

    components: {
      Adv,
      ArticleVideos,
      VerticalArticles,
      VerticalArticlesContainer,
      HorizontalArticles,
      WeatherSection,
      MainSection
    },

    data() {
      return {
        sections: {
          sport: {
            lat: 'Sport',
            cir: 'Спорт'
          },

          bhrtteme: {
            lat: 'BHRT TEME',
            cir: 'БХРТ ТЕМЕ'
          },

          ekonomija: {
            lat: 'Ekonomija',
            cir: 'Економија'
          },

          dir: {
            lat: 'Društvo i religija',
            cir: 'Друштво и религија'
          },

          bih: {
            lat: 'Bosna i Hercegovina',
            cir: 'Босна и Херцеговина'
          },

          region: {
            lat: 'Region',
            cir: 'Регион'
          },

          svijet: {
            lat: 'Svijet',
            cir: 'Свијет'
          },

          kultura: {
            lat: 'Kultura',
            cir: 'Култура'
          },

          video: {
            lat: 'Video',
            cir: 'Видео'
          },

          mozaik: {
            lat: 'Mozaik',
            cir: 'Мозаик'
          },

          emisije: {
            lat: 'Najnovije emisije',
            cir: 'Најновије емисије'
          }
        },
      }
    },

    mounted() {
      eventBus.$on('langChange', payload => this.requestPageData(`?lng=${payload.lang}`))
      this.requestPageData(`?lng=${localStorage.getItem('lang')}`);
    }
  }
</script>
