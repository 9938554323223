<template>
  <div id="header-menu" class="navbar-toggle sb-toggle-right">
    <img
      src="../assets/images/header-menu-icon.svg"
      id="header-menu-icon"
      @click="toggleNav"
    />
    <div class="sb-slidebar sb-right d-none" id="hamburger">
      <ul class="nav navbar-nav navbar-right">
        <li
          v-for="(specialLink, key) in specialLinks[lang]"
          :key="`${key}-top-nav-item`"
          class="dropdown moblink burgerspeclink"
        >
          <a
            :href="specialLink.url"
            :title="specialLink.caption"
            target="_self"
          >
            {{ specialLink.caption }}
          </a>
        </li>
        <li
          v-for="(topNavItem, topNavItemKey) in topNavitems"
          :key="`${topNavItemKey}-top-nav-item`"
          class="dropdown moblink"
        >
          <nav-item :nav-item="topNavItem" @click.native="toggleNav" />
        </li>

        <li class="dropdown moblink">
          <a href="#" @click="changeLanguage">
            {{ nextLanguage }}
          </a>
        </li>

        <li
          v-for="(navItem, navItemKey) in navItems"
          :key="`${navItemKey}-nav-item`"
          class="dropdown moblink"
        >
          <nav-item :nav-item="navItem" @click.native="toggleNav" />

          <a
            v-if="navItem.hasOwnProperty('subItems')"
            href="#"
            class="dropdown-toggle menu-arrow"
            data-toggle="dropdown"
            @click.prevent="toogleDropdown"
          >
            <i class="fa fa-chevron-down"></i>
          </a>

          <ul v-if="navItem.hasOwnProperty('subItems')" class="dropdown-menu">
            <li
              v-for="(subItem, subItemKey) in navItem.subItems"
              :key="`${subItemKey}-sub-item`"
            >
              <nav-item :nav-item="subItem" @click.native="toggleNav" />
            </li>
          </ul>
        </li>

        <li
          v-for="(hamburgerNavItem, hamburgerNavItemKey) in hamburgerNavItems"
          :key="`${hamburgerNavItemKey}-hamburger-nav-item`"
          class="dropdown"
        >
          <nav-item :nav-item="hamburgerNavItem" @click.native="toggleNav" />
        </li>
      </ul>

      <hamburger-social-media />
    </div>
  </div>
</template>

<script>
import HamburgerSocialMedia from "./HamburgerSocialMedia";
import TopMixin from "../mixins/nav/TopMixin";
import LangMixin from "../mixins/LangMixin";
import MainMixin from "../mixins/nav/MainMixin";
import HamburgerMixin from "../mixins/nav/HamburgerMixin";
import NavItem from "../components/NavItem";

export default {
  components: {
    NavItem,
    HamburgerSocialMedia,
  },
  data() {
    return {
      specialLinks: [],
    };
  },
  mounted() {
    this.requestSepcialLinks();
  },

  mixins: [LangMixin, TopMixin, MainMixin, HamburgerMixin],

  methods: {
    toggleNav() {
      document.querySelector("#hamburger").classList.toggle("d-none");
    },

    toogleDropdown(event) {
      event.target.parentElement.parentElement
        .querySelector(".dropdown-menu")
        .classList.toggle("d-block");
    },

    changeLanguage() {
      this.emitLangChange();
      this.toggleNav();
    },
    requestSepcialLinks() {
      fetch(`${this.$bhrt.api_uri}/amenu`)
        .then((response) => response.json())
        .then((data) => (this.specialLinks = data.result))
        .catch((error) => console.log(error));
    },
  },
};
</script>