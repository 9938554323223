<template>
  <div class="weatherContSingle">
    <img :src="`https://openweathermap.org/img/wn/${forcastCity.data.weather[0].icon}@2x.png`" class="weatherIcon" />

    <div class="weatherGrad">
      {{ forcastCity.data.name }}
    </div>

    <div class="weatherTemp">
      {{ Math.round(forcastCity.data.main.temp) }}°C
    </div>

    <div class="weatherDesc">
      {{ forcastCity.data.weather[0].description }}
    </div>

    <div class="weatherInfo">
      {{ weatherInfo.humidity[lang] }}: {{ forcastCity.data.main.humidity }}% <br/>
      {{ weatherInfo.wind[lang] }}: {{ forcastCity.data.wind.speed }}m/s <br/>
      {{ weatherInfo.clouds[lang] }}: {{ forcastCity.data.clouds.all }}%
    </div>
  </div>
</template>

<script>
  import LangMixin from "../../mixins/LangMixin";

  export default {
    mixins: [
        LangMixin
    ],

    props: {
      forcastCity: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        weatherInfo: {
          humidity: {
            lat: 'Vlažnost',
            cir: 'Влажност'
          },

          wind: {
            lat: 'Vjetar',
            cir: 'Ветар'
          },

          clouds: {
            lat: 'Naoblaka',
            cir: 'Наоблака'
          }
        }
      }
    }
  }
</script>