<template>
  <router-link :to="article.url" class="mNR_cont">
    <div class="mNR_time">
      {{ dateDiff(article.DTPublished) }}
    </div>

    <div class="mNR_title">
      {{ article.Title }}
    </div>
  </router-link>
</template>

<script>
  import ArticleMixin from "../mixins/ArticleMixin";
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
        LangMixin,
        ArticleMixin
    ],

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>