<template>
  <medium-box :url="decodeUrl(article.url)" :img="resolveBgImg(article.MainImage)">
    <template v-slot:tag>
      {{ article.PageTag }}
    </template>

    <template v-slot:title>
      {{ article.Title }}
    </template>

    <template v-slot:description>
      {{ dateDiff(article.DTPublished) }}
    </template>
  </medium-box>
</template>

<script>
  import ArticleMixin from "../mixins/ArticleMixin";
  import LangMixin from "../mixins/LangMixin";
  import MediumBox from "./MediumBox";

  export default {
    mixins: [
        ArticleMixin,
        LangMixin,
    ],

    components: {
      MediumBox
    },

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>