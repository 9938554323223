export default {
    api_uri: '/api',
    img_uri: '/uploads',
    live_uri:{
        tvhd:"https://bhrtstream.bhtelecom.ba/bhrtportal_hd.m3u8",
        tv:"https://bhrtstream.bhtelecom.ba/bhrtportal.m3u8",
        bhr1:"https://webtvstream.bhtelecom.ba/bh_radio1.m3u8",
        твхд:"https://bhrtstream.bhtelecom.ba/bhrtportal_hd.m3u8",
        тв:"https://bhrtstream.bhtelecom.ba/bhrtportal.m3u8",
        бхр1:"https://webtvstream.bhtelecom.ba/bh_radio1.m3u8",
    },
    live_titles: {
        tvhd: "TV program uživo HD",
        tv: "TV program uživo",
        bhr1: "BHR1 program uživo",
        твхд: "ТВ програм уживо ХД",
        тв: "ТВ програм уживо",
        бхр1: "БХР1 програм уживо",
      },
    document_titles:{
        cir:"БХРТ - Радиотелевизија Босне и Херцеговине",
        lat:"BHRT - Radiotelevizija Bosne i Hercegovine"
    }  
    // api_uri: '/api',
    // img_uri: '/uploads'
}