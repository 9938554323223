<template>
  <div id="mainNewsRestcont">
    <div id="mNR_header">
      <div
          v-for="(tab, key) in tabs"
          :key="key"
          class="mNR_tab"
          :class="{ 'active' : tab.id === activeTab }"
          :id="tab.id"
          @click="activeTab = tab.id"
      >
        {{ tab.name[lang] }}
      </div>
    </div>


    <div id="mNR_cont">
      <tab-item v-for="(article, key) in $props[activeTab]" :key="key" :article="article"/>
    </div>
  </div>
</template>

<script>
  import TabItem from '../../components/ArticleItem';
  import LangMixin from "../../mixins/LangMixin";

  export default {
    components: {
      TabItem
    },

    mixins: [
        LangMixin
    ],

    props: {
      novo: {
        type: Array,
        required: true
      },

      popularno: {
        type: Array,
        required: true
      },

      promo: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        tabs: [
          {
            name: {
              lat: 'Novo',
              cir: 'Новo'
            },

            id: 'novo'
          },

          {
            name: {
              lat: 'Popularno',
              cir: 'Популарно'
            },

            id: 'popularno'
          },

          {
            name: {
              lat: 'Promo',
              cir: 'Промо'
            },

            id: 'promo'
          }
        ],

        activeTab: 'novo'
      }
    }
  }
</script>