<template>
  <div class="container marginTop">
    <div id="footer">
      <div id="foterDiv">
        <a href="/" title="Naslovna">
          <img src="../assets/images/logo_footer.svg" />
        </a>

        <span class="clearfix"></span>

        <a href="#" :title="nextLanguage" id="foter_lang" @click.prevent="emitLangChange">
          {{ nextLanguage }}
        </a>
      </div>

      <div id="footerRest">
        <socials-footer/>
        <footer-menu/>

        <div id="copyright">
          Radiotelevizija Bosne i Hercegovine - Sva prava pridržana

          <span class="clearfix"></span>

          <span>
              design & development <a href="https://www.dws.ba/" title="Djikic Web Studio" target="_blank">DWS</a> 2021
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import SocialsFooter from "./SocialsFooter";
  import FooterMenu from "./FooterMenu";

  export default {
    components: {
      FooterMenu,
      SocialsFooter
    },

    mixins: [
        LangMixin
    ]
  }
</script>