<template>
  <router-link
      :to="decodeUrl(article.url)"
      class="NC_main_news bg-img-position"
      :style="resolveBgImg(article.MainImage)"
  >
    <div class="NewsCover">
      <div class="mainNewsdiv">
        <div class="mainNewsTitle">
          {{ article.Title }}
        </div>

        <div class="mainNewsTime">
          {{ dateDiff(article.DTPublished) }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
  import ArticleMixin from "../mixins/ArticleMixin";
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
        ArticleMixin,
        LangMixin
    ],

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>