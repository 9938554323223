<template>
  <div class="container mainNewscont">
    <main-article :article="top3vijesti[0]"/>

    <div id="mainNewsSml">
      <side-article :article="top3vijesti[1]"/>
      <side-article :article="top3vijesti[2]"/>
    </div>

    <article-tabs :novo="novo" :popularno="popularno" :promo="promo"/>
    <span class="clearfix"></span>
  </div>
</template>

<script>
  import MainArticle from './MainArticle';
  import SideArticle from './SideArticle';
  import ArticleTabs from "./ArticleTabs";

  export default {
    components: {
      MainArticle,
      SideArticle,
      ArticleTabs
    },

    props: {
      top3vijesti: {
        type: Array,
        required: true
      },

      novo: {
        type: Array,
        required: true
      },

      popularno: {
        type: Array,
        required: true
      },

      promo: {
        type: Array,
        required: true
      }
    }
  }
</script>