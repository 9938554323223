<template>
  <div id="header_top_menu">
    <transition name="fade-x">
      <input
          v-model="search"
          :placeholder="searchPlaceholder[lang]"
          id="searchbar"
          v-if="showSearch"
          @keypress="getSearchResults"
      >
    </transition>

    <img
        src="../assets/images/htm_search.svg"
        id="htm_search"
        @click="showSearch = !showSearch"
    />

    <div class="htm_line first"></div>

    <div v-for="(topNavItem, key) in topNavitems" :key="key" class="d-inline">
      <nav-item :nav-item="topNavItem" class="htm_links"/>
      <div class="htm_line"></div>
    </div>

    <a href="#" :title="nextLanguage" class="htm_links" @click.prevent="emitLangChange">
      {{ nextLanguage }}
    </a>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import HeaderTopMenuMixin from "../mixins/nav/TopMixin";
  import NavItem from "../components/NavItem";

  export default {
    mixins: [
        LangMixin,
        HeaderTopMenuMixin
    ],

    components: {
      NavItem
    },

    data() {
      return {
        search: '',
        showSearch: false,

        searchPlaceholder: {
          lat: 'Unesite traženi izraz',
          cir: 'Унесите тражени израз'
        }
      }
    },

    methods: {
      getSearchResults(event) {
        if (event.keyCode !== 13) return;
        const search = this.search;
        const slug = this.lang === 'lat' ? 'rezultati-pretrage' : 'резултати-претраге'
        this.search = '';
        this.$router.push(`/${slug}?q=${search}`)
      }
    }
  }
</script>