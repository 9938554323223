export default {
    data() {
        return {
            days: {
                lat: {
                    plural: 'dana',
                    singular: 'dan'
                },

                cir: {
                    plural: 'данa',
                    singular: 'дан'
                }
            },

            hrs: {
                lat: {
                    plural: [
                        'sata',
                        'sati'
                    ],
                    singular: 'sat'
                },

                cir: {
                    plural: [
                        'сатa',
                        'сати'
                    ],
                    singular: 'сат'
                }
            },

            min: {
                lat: 'min',
                cir: 'мин'
            }
        }
    },

    methods: {
        dateDiff(date) {
            const now = new Date();
            const comparedDate = new Date(date);
            comparedDate.setTime(comparedDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000);

            const diff = Math.max(now, comparedDate) - Math.min(now, comparedDate);
            const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN, DAYS = 86400 * SEC;

            const days = Math.floor(diff / DAYS);
            const hrs = Math.floor((diff % DAYS) / HRS).toLocaleString('en-US', { minimumIntegerDigits: 2 });
            const min = Math.floor((diff % HRS) / MIN).toLocaleString('en-US', { minimumIntegerDigits: 2 });

            if (days) return `${days} ${this.getDaysLabel(days)}`;
            if (hrs !== '00') return `${this.removeLeadingZero(hrs)} ${this.getHrsLabel(hrs)}`;
            if (min !== '00') return `${this.removeLeadingZero(min)} ${this.min[this.lang]}`;
        },

        getLabel(number, labelName) {
            const label = this[labelName][this.lang];
            return number == 1 ? label.singular : label.plural;
        },

        getDaysLabel(day) {
            return this.getLabel(day, 'days')
        },

        getHrsLabel(hrs) {
            const hrsLabel = this.getLabel(hrs, 'hrs');
            if (typeof hrsLabel === 'string') {
                return hrsLabel;
            }

            return hrsLabel[['2', '3', '4', '22', '23', '24'].includes(this.removeLeadingZero(hrs)) ? 0 : 1];
        },

        removeLeadingZero(number) {
            return number.replace(/^0+/, '');
        },

        addLeadingZero(number) {
            if (number >= 10) {
                return number;
            }

            return `0${number}`;
        },

        getFormatedDate(date) {
            const dateObject = new Date(date);
            const day = this.addLeadingZero(dateObject.getDate());
            const month = this.addLeadingZero(dateObject.getMonth() + 1);
            const year = dateObject.getFullYear();

            return `${day}.${month}.${year}.`
        },

        decodeUrl(url) {
            try {
                return decodeURIComponent(url)
            } catch (e) {
                return url;
            }
        },

        resolveImg(img) {
            if (!img) return ''
            return this.isUrl(img) || img[0] === '/' ? img : `${this.$bhrt.img_uri}/${img}`;
        },

        resolveBgImg(img) {
            return { backgroundImage: `url('${this.resolveImg(img)}')` }
        },

        isUrl(url) {
            try {
                new URL(url)
            } catch (e) {
                return false;
            }

            return true;
        },

        resolveUrlTarget(url) {
            return this.isUrl(url) ? '_blank' : '_self';
        }
    }
}