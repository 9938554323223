<template>
    <div class="NewsCont">
      <div class="newsContTitle" :class="titleBadgeBig ? 'bg' : 'sml'">
        {{ title[lang] }}
      </div>

      <main-vertical-article :article="mainArticle"/>

      <div class="NC_rest_news" :class="{ 'sml' : articleContainerSmall }">
        <article-item
            v-for="(sideArticle, key) in sideArticles"
            :key="`${key}-side-article`"
            :article="sideArticle"
        />
      </div>
    </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import MainVerticalArticle from "./MainVerticalArticle";
  import ArticleItem from "./ArticleItem";

  export default {
    components: {ArticleItem, MainVerticalArticle},

    mixins: [
        LangMixin
    ],

    props: {
      titleBadgeBig: {
        type: Boolean,
        required: false,
        default: false
      },

      articleContainerSmall: {
        type: Boolean,
        required: false,
        default: false
      },

      articles: {
        type: Array,
        required: true
      },

      limit: {
        type: Number,
        required: true
      },

      title: {
        type: Object,
        required: true
      }
    },

    computed: {
      mainArticle() {
        return this.articles[0];
      },

      sideArticles() {
        return this.articles.slice(1, this.limit);
      }
    }
  }
</script>