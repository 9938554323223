<template>
  <component
      :is="component"
      :to="url"
      :href="url"
      :title="title"
      :target="target"
      :onclick="action"
  >
    <slot v-bind:title="title">
      {{ title }}
    </slot>
  </component>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
      LangMixin
    ],

    props: {
      navItem: {
        type: Object,
        required: true
      }
    },

    computed: {
      url() {
        if ('external_url' in this.navItem) {
          return this.navItem.external_url
        }

        if ('internal_url' in this.navItem) {
          return this.navItem.internal_url[this.lang];
        }

        return '#'
      },

      title() {
        return this.navItem.title[this.lang];
      },

      target() {
        if('target' in this.navItem){
          return this.navItem.target;
        }
        if ('external_url' in this.navItem) {
          return '_blank';
        }

        if ('internal_url' in this.navItem) {
          return '_self'
        }

        return null;
      },

      action(){
        if('action' in this.navItem){
          return this.navItem.action;
        }
        return null;
      },

      component() {
        if ('internal_url' in this.navItem) {
          return 'router-link'
        }

        return 'a'
      }
    },
  }
</script>