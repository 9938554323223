<template>
  <div id="app">
    <div id="sb-site">
      <div class="container-fluid bodycf">
        <page-header />
        <router-view @pageLoaded="loaded = true" @pageLoading="loaded = false" v-show="loaded"/>
        <loading v-show="!loaded"/>
        <page-footer/>
      </div>

      <span class="clearfix"></span>
    </div>
  </div>
</template>

<script>
  import PageHeader from "./partials/PageHeader";
  import PageFooter from "./partials/PageFooter";
  import Loading from "./components/Loading";
  import eventBus from "./eventBus";

  export default {
    components: {
      Loading,
      PageHeader,
      PageFooter
    },

    mixins: [
        eventBus
    ],

    data() {
      return {
        loaded: false
      }
    },

    // watch: {
    //   $route() {
    //     console.log(this.$route.fullPath);
    //   }
    // }
  }
</script>

<style>
  @import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
  @import './assets/css/main.css';
  @import './assets/css/main-responsive.css';
  @import './assets/css/slidebars.css';

  .bg-img-position {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
  }
</style>
