import NavMixin from "./NavMixin";

export default {
    mixins: [
        NavMixin
    ],

    data() {
        return {
            topNavitems: [
                {
                    title: {
                        lat: 'BHRT',
                        cir: 'БХРТ'
                    },

                    internal_url: {
                        lat: '/bhrt',
                        cir: '/бхрт'
                    }
                },

                {
                    title: {
                        lat: 'Pregled programa',
                        cir: 'Преглед програма'
                    },

                    internal_url: {
                        lat: '/pregled-programa',
                        cir: '/преглед-програма'
                    }
                },
            ]
        }
    }
}