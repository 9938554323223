<template>
  <div v-if="ad" class="container reklama">
    <div v-if="ad.type === 'custom'" v-html="ad.properties.code"></div>

    <Adsense
        v-if="ad.type === 'adsense'"
        :data-ad-client="ad.properties.client"
        :data-ad-slot="ad.properties.slot"
        data-full-width-responsive="yes"
        data-ad-test="on"
    >
    </Adsense>
  </div>
</template>

<script>
  export default {
    props: {
      ad: {
        type: [Object, String]
      }
    }
  }
</script>