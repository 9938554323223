import NavMixin from "./NavMixin";

export default {
    mixins: [
        NavMixin
    ],

    data() {
        return {
            navItems: [
                {
                    title: {
                        lat: "Vijesti",
                        cir: 'Bијести'
                    },

                    subItems: [
                        {
                            title: {
                                lat: 'BiH',
                                cir: 'БиХ'
                            },

                            internal_url: {
                                lat: '/vijesti-bih',
                                cir: '/вијести-бих'
                            }
                        },

                        {
                            title: {
                                lat: 'Region',
                                cir: 'Регион'
                            },

                            internal_url: {
                                lat: '/vijesti-region',
                                cir: '/вијести-регион'
                            }
                        },

                        {
                            title: {
                                lat: 'Svijet',
                                cir: 'Свијет'
                            },

                            internal_url: {
                                lat: '/vijesti-svijet',
                                cir: '/вијести-свијет'
                            }
                        },
                    ]
                },

                {
                    title: {
                        lat: 'Ekonomija',
                        cir: 'Економија'
                    },

                    internal_url: {
                        lat: '/ekonomija',
                        cir: '/економија'
                    }
                },

                {
                    title: {
                        lat: 'Sport',
                        cir: 'Спорт'
                    },

                    internal_url: {
                        lat: '/sport',
                        cir: '/спорт'
                    }
                },

                {
                    title: {
                        lat: 'Kultura',
                        cir: 'Култура'
                    },

                    internal_url: {
                        lat: '/kultura',
                        cir: '/култура'
                    }
                },

                {
                    title: {
                        lat: 'Mozaik',
                        cir: 'Мозаик'
                    },

                    internal_url: {
                        lat: '/mozaik',
                        cir: '/мозаик'
                    }
                },

                {
                    title: {
                        lat: 'Emisije',
                        cir: 'Емисије'
                    },

                    icon: 'emisije_icon.svg',

                    subItems: [
                        {
                            title: {
                                lat: 'Tv emisije',
                                cir: 'TV емисије'
                            },

                            internal_url: {
                                lat: '/emisije-tv',
                                cir: '/емисије-тв'
                            }
                        },

                        {
                            title: {
                                lat: 'Radio emisije',
                                cir: 'Радио емисије'
                            },

                            internal_url: {
                                lat: '/emisije-radio',
                                cir: '/емисије-радио'
                            }
                        },

                        {
                            title: {
                                lat: 'YouTube ToS',
                                cir: 'YouTube ToS'
                            },

                            external_url:  'https://www.youtube.com/t/terms'
                        },

                        {
                            title: {
                                lat: 'Google Privacy',
                                cir: 'Google Privacy'
                            },

                            external_url: 'https://policies.google.com/privacy'
                            
                        }
                    ]
                },

                {
                    title: {
                        lat: 'Uživo',
                        cir: 'Уживо'
                    },

                    icon: 'uzivo_icon.svg',

                    subItems: [
                        {
                            title: {
                                lat: 'Program uživo HD',
                                cir: 'Програм уживо ХД'
                            },

                            internal_url: {
                                lat: '/uzivo/tvhd',
                                cir: '/уживо/твхд'
                            }
                        },

                        {
                            title: {
                                lat: 'TV uživo',
                                cir: 'ТВ уживо'
                            },

                            internal_url: {
                                lat: '/uzivo/tv',
                                cir: '/уживо/тв'
                            }
                        },

                        {
                            title: {
                                lat: 'Radio uživo',
                                cir: 'Радио уживо'
                            },

                            internal_url: {
                                lat: '/uzivo/bhr1',
                                cir: '/уживо/бхр1'
                            }
                        },

                        {
                            title: {
                                lat: 'iRadio stanice',
                                cir: 'иРадио станице'
                            },

                            external_url: 'JavaScript:void(0);',
                            target:'popup',
                            action:`window.open('http://iradio.bhrt.ba', 'iradiostanice', 'width=375,height=667,toolbar=no,menubar=no,location=no,status=no,scrollbars=no,resizable=no,left=0,top=0');`
                        }
                    ]
                }
            ]
        }
    }
}