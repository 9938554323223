<template>
  <router-link
      :to="decodeUrl(article.url)"
      class="mainNewsdivSml bg-img-position"
      :style="resolveBgImg(article.MainImage)"
  >
    <div class="NewsCover">
      <div class="mainNewsdivSmlCont">
        <div class="mainNewsKat blue">
          {{ article.PageTag }}
        </div>

        <div class="mainNewsTitle sml">
          {{ article.Title  }}
        </div>

        <div class="mainNewsTime">
          {{ dateDiff(article.DTPublished) }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
  import LangMixin from "../../mixins/LangMixin";
  import ArticleMixin from "../../mixins/ArticleMixin";

  export default {
    mixins: [
      LangMixin,
      ArticleMixin
    ],

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>