<template>
  <component
      :is="component"
      :to="url"
      :href="url"
      class="MainNews20 bg-img-position"
      :style="img"
      :target="resolveUrlTarget(url)"
  >
    <div class="NewsCover">
      <div class="mainNewsdivSmlCont">
        <div class="mainNewsKat blue" v-if="isEnabled('tag')">
          <slot name="tag"></slot>
        </div>

        <div class="mainNewsTitle sml" v-if="isEnabled('title')">
          <slot name="title"></slot>
        </div>

        <div class="mainNewsTime" v-if="isEnabled('description')">
          <slot name="description"></slot>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
  import BoxMixin from "../mixins/BoxMixin";
  import ArticleMixin from "../mixins/ArticleMixin";

  export default {
    mixins: [
        BoxMixin,
        ArticleMixin
    ]
  }
</script>