<template>
  <div class="container weatherCont">
    <div class="weatherDiv">
      <VueSlickCarousel v-bind="settings" v-if="getWidth() < 450">
        <forcast-city v-for="(forcastCity, key) in forcastCities" :key="`${key}-mobile`" :forcastCity="forcastCity"/>
      </VueSlickCarousel>


      <forcast-city v-else v-for="(forcastCity, key) in forcastCities" :key="`${key}-desktop`" :forcastCity="forcastCity"/>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
 import ForcastCity from "./ForcastCity";
 import SlickMixin from "../../mixins/SlickMixin";
 import ForcastSlickSettings from "../../mixins/ForcastSlickSettings";

  export default {
    mixins: [
        SlickMixin,
        ForcastSlickSettings
    ],

    components: {
      ForcastCity,
    },

    props: {
      forcastCities: {
        type: Array,
        requred: true
      }
    },
  }
</script>