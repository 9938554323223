<template>
  <router-link :to="decodeUrl(article.url)" class="newsDivRest33">
    <div
        class="newsDivRest33_img bg-img-position"
        :style="resolveBgImg(article.MainImage)">
    </div>

    <div class="mNR_cont">
      <div class="mNR_time">
        {{ dateDiff(article.DTPublished) }}
      </div>

      <div class="mNR_title">
        {{ article.Title }}
      </div>
    </div>
  </router-link>
</template>

<script>
  import ArticleMixin from "../mixins/ArticleMixin";
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
        ArticleMixin,
        LangMixin
    ],

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>