<template>
  <div class="container marginTop">
    <div class="NewsDiv sngl">
      <div class="NewsDivTitle smlTtl">
        {{ title[lang] }}
      </div>

      <div class="NewsDiv20">
        <article-medium-box
            v-for="(mainArticle, articleMediumKey) in mainArticles"
            :key="`${articleMediumKey}-article-medium`"
            :article="mainArticle"
        />
      </div>

      <div v-if="showSmalBoxes" class="NewsDiv20Rest">
        <article-small-box
            v-for="(sideArticle, articleSmallKey) in sideArticles"
            :key="`${articleSmallKey}-article-small`"
            :article="sideArticle"
        />
      </div>
    </div>
    <span class="clearfix"></span>
  </div>
</template>

<script>
  import ArticleMediumBox from "./ArticleMediumBox";
  import ArticleSmallBox from "./ArticleSmallBox";
  import LangMixin from "../mixins/LangMixin";

  export default {
    components: {
      ArticleSmallBox,
      ArticleMediumBox
    },

    mixins: [
        LangMixin
    ],

    props: {
      title: {
        type: Object,
        required: true
      },

      articles: {
        type: Array,
        required: true
      },

      showSmalBoxes: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    computed: {
      mainArticles() {
        return this.articles.slice(0, 4);
      },

      sideArticles() {
        return this.articles.slice(4);
      }
    }
  }
</script>