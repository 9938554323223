<template>
  <a
      :href="`https://www.youtube.com/watch?v=${article.id.videoId}`"
      :title="article.snippet.title"
      target="_blank"
  >
    <div class="emisije_slide">
      <div
          class="emisije_slide_img bg-img-position"
          :style="resolveBgImg(article.snippet.thumbnails.medium.url)">
      </div>

      <div class="emisije_slide_cont">
        <div class="emisije_slide_title" v-html="article.snippet.title">
        </div>

        <div class="emisije_slide_date">
          [{{ getFormatedDate(article.snippet.publishedAt) }}]
        </div>

        <div class="emisije_slide_link">
          <img src="../assets/images/emisije_slide_link.svg" />
        </div>
      </div>
    </div>
  </a>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import ArticleMixin from "../mixins/ArticleMixin";

  export default {
    mixins: [
        LangMixin,
        ArticleMixin
    ],

    props: {
      article: {
        type: Object,
        required: true
      }
    },
  }
</script>