export default {
    props: {
        url: {
            type: String,
            requred: true
        },

        img: {
            type: Object,
            required: true
        },

        disabled: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    computed: {
        component() {
            return this.isUrl(this.url) ? 'a' : 'router-link';
        }
    },

    methods: {
        isEnabled(slot) {
            return !this.disabled.includes(slot);
        },
    },
}