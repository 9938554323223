export default {
    data() {
        return {
            ads: {
                11: '',
                12: '',
                13: '',
                14: '',
                21: '',
                22: ''
            },
        }
    },

    mounted() {
        this.requestAds();
    },

    methods: {
        requestAds() {
            fetch(`${this.$bhrt.api_uri}/ads`)
                .then(response => response.json())
                .then(data => this.parseAds(data.data))
        },

        parseAds(ads) {
            ads.forEach(ad => this.ads[ad.position] = ad)
        },
    }
}