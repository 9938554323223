import NavMixin from "./NavMixin";

export default {
    mixins: [
        NavMixin
    ],

    data() {
        return {
            footerNavItems: [
                [
                    {
                        title: {
                            lat: "Vijesti",
                            cir: 'Вијести'
                        },

                        subItems: [
                            {
                                title: {
                                    lat: 'Bosna i Hercegovina',
                                    cir: 'БиХ'
                                },

                                internal_url: {
                                    lat: '/vijesti-bih',
                                    cir: '/вијести-бих'
                                }
                            },

                            {
                                title: {
                                    lat: 'Region',
                                    cir: 'Регион'
                                },

                                internal_url: {
                                    lat: '/vijesti-region',
                                    cir: '/вијести-регион'
                                }
                            },

                            {
                                title: {
                                    lat: 'Svijet',
                                    cir: 'Свијет'
                                },

                                internal_url: {
                                    lat: '/vijesti-svijet',
                                    cir: '/вијести-свијет'
                                }
                            },
                        ]
                    },

                    {
                        title: {
                            lat: 'Ekonomija',
                            cir: 'Економија'
                        },

                        internal_url: {
                            lat: '/ekonomija',
                            cir: '/економија'
                        }
                    },

                    {
                        title: {
                            lat: 'Sport',
                            cir: 'Спорт'
                        },

                        internal_url: {
                            lat: '/sport',
                            cir: '/спорт'
                        }
                    },

                    {
                        title: {
                            lat: 'Kultura',
                            cir: 'Култура'
                        },

                        internal_url: {
                            lat: '/kultura',
                            cir: '/култура'
                        }
                    },

                    {
                        title: {
                            lat: 'Društvo i religija',
                            cir: 'Друштво и религија'
                        },

                        internal_url: {
                            lat: '/drustvo-i-religija',
                            cir: '/друштво-и-религија'
                        }
                    },

                    {
                        title: {
                            lat: 'Video',
                            cir: 'Bидео'
                        },

                        internal_url: {
                            lat: '/video',
                            cir: '/видео'
                        }
                    },


                    {
                        title: {
                            lat: 'BHRT teme',
                            cir: 'БХРТ теме'
                        },

                        internal_url: {
                            lat: '/bhrt-teme',
                            cir: '/бхрт-теме'
                        }
                    },

                    {
                        title: {
                            lat: 'Mozaik',
                            cir: 'Мозаик'
                        },

                        internal_url: {
                            lat: '/mozaik',
                            cir: '/мозаик'
                        }
                    },
                ],

                [
                    {
                        title: {
                            lat: 'BHRT',
                            cir: 'БХРТ'
                        },

                        internal_url: {
                            lat: '/bhrt',
                            cir: '/бхрт'
                        }
                    },

                    {
                        title: {
                            lat: 'BHT1',
                            cir: 'БХТ1'
                        },

                        internal_url: {
                            lat: '/bht1',
                            cir: '/бхт1'
                        }
                    },

                    {
                        title: {
                            lat: 'BHR1',
                            cir: 'БХР1'
                        },

                        internal_url: {
                            lat: '/bhr1',
                            cir: '/бхр1'
                        }
                    },

                    {
                        title: {
                            lat: 'MP BHRT',
                            cir: 'МП БХРТ'
                        },

                        internal_url: {
                            lat: '/mp-bhrt',
                            cir: '/мп-бхрт'
                        }
                    },

                    {
                        title: {
                            lat: 'Oglasi',
                            cir: 'Огласи'
                        },

                        internal_url: {
                            lat: '/oglasi',
                            cir: '/огласи'
                        }
                    },

                    {
                        title: {
                            lat: 'Marketing',
                            cir: 'Маркетинг'
                        },

                        internal_url: {
                            lat: '/marketing',
                            cir: '/маркетинг'
                        }
                    },

                    {
                        title: {
                            lat: 'RTV taksa',
                            cir: 'РТВ такса'
                        },

                        internal_url: {
                            lat: '/rtv-taksa',
                            cir: '/ртв-такса'
                        }
                    },

                    {
                        title: {
                            lat: 'Kontakti',
                            cir: 'Контакти'
                        },

                        internal_url: {
                            lat: '/kontakti',
                            cir: '/контакти'
                        }
                    },

                    {
                        title: {
                            lat: 'Zaštita podataka',
                            cir: 'Заштита података'
                        },

                        internal_url: {
                            lat: '/izjava-o-zastiti-licnih-osobnih-podataka',
                            cir: '/изјава-о-заштити-личних-особних-података'
                        }
                    },

                    {
                        title: {
                            lat: 'Privacy policy',
                            cir: 'Privacy policy'
                        },

                        internal_url: {
                            lat: '/personal-data-protection-statement',
                            cir: '/personal-data-protection-statement'
                        }
                    }
                ],

                [
                    {
                        title: {
                            lat: 'TTX',
                            cir: 'TTX'
                        },

                        external_url: 'http://teletext.bhrt.ba/?_gl=1*13g6vv6*_ga*MTUzNTgxOTY1MS4xNjExMTM2OTY0*_ga_GTFVRM0KG7*MTYyNjg1ODI0Ny4yNC4xLjE2MjY4NjA3NDEuMzk.&_ga=2.80922772.2069584321.1626858251-1535819651.1611136964'
                    },

                    {
                        title: {
                            lat: 'Pregled programa',
                            cir: 'Преглед програма'
                        },

                        internal_url: {
                            lat: '/pregled-programa',
                            cir: '/преглед-програма'
                        }
                    },


                    {
                        title: {
                            lat: 'Javne nabavke',
                            cir: 'Јавне набавке'
                        },

                        external_url: 'http://javnenabavke.bhrt.ba/?_gl=1*uaftm5*_ga*MTUzNTgxOTY1MS4xNjExMTM2OTY0*_ga_GTFVRM0KG7*MTYyNjg1ODI0Ny4yNC4xLjE2MjY4NjA3NDEuMzk.&_ga=2.117681034.2069584321.1626858251-1535819651.1611136964'
                    },
                ],

                [
                    {
                        title: {
                            lat: 'Emisije',
                            cir: 'Емисије'
                        },

                        id: 'footer_emisija',

                        subItems: [
                            {
                                title: {
                                    lat: 'TV emisije',
                                    cir: 'TV емисије'
                                },

                                internal_url: {
                                    lat: '/emisije-tv',
                                    cir: '/емисије-тв'
                                }
                            },

                            {
                                title: {
                                    lat: 'Radio emisije',
                                    cir: 'Радио емисије'
                                },

                                internal_url: {
                                    lat: '/emisije-radio',
                                    cir: '/емисије-радио'
                                }
                            },

                            {
                                title: {
                                    lat: 'YouTube ToS',
                                    cir: 'YouTube ToS'
                                },

                                external_url:  'https://www.youtube.com/t/terms'
                            },

                            {
                                title: {
                                    lat: 'Google Privacy',
                                    cir: 'Google Privacy'
                                },

                                external_url: 'https://policies.google.com/privacy'
                                
                            }
                        ]
                    },
                ],

                [
                    {
                        title: {
                            lat: 'Uživo',
                            cir: 'Уживо'
                        },

                        id: 'footer_uzivo',

                        subItems: [
                            {
                                title: {
                                    lat: 'Program uživo HD',
                                    cir: 'Програм уживо ХД'
                                },

                                internal_url: {
                                    lat: '/uzivo/tvhd',
                                    cir: '/уживо/твхд'
                                }
                            },

                            {
                                title: {
                                    lat: 'TV uživo',
                                    cir: 'ТВ уживо'
                                },

                                internal_url: {
                                    lat: '/uzivo/tv',
                                    cir: '/уживо/тв'
                                }
                            },

                            {
                                title: {
                                    lat: 'Radio uživo',
                                    cir: 'Радио уживо'
                                },

                                internal_url: {
                                    lat: '/uzivo/bhr1',
                                    cir: '/уживо/бхр1'
                                }
                            },

                            {
                                title: {
                                    lat: 'iRadio stanice',
                                    cir: 'иРадио станице'
                                },

                                external_url: 'JavaScript:void(0);',
                                target:'popup',
                                action:`window.open('http://iradio.bhrt.ba', 'iradiostanice', 'width=375,height=667,toolbar=no,menubar=no,location=no,status=no,scrollbars=no,resizable=no,left=0,top=0');`
                            }
                        ]
                    }
                ]
            ]
        }
    }
}