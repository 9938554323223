import eventBus from "../eventBus";
import globals from "../globals";

export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'lat',
            languages: {
                lat: 'LATINICA',
                cir: 'ЋИРИЛИЦА'
            }
        }
    },

    mounted() {
        eventBus.$on('langChange', payload => this.setLang(payload.lang));
        document.title = globals.document_titles[this.lang];
    },

    computed: {
        nextLanguage() {
            return this.languages[this.lang === 'lat' ? 'cir' : 'lat'];
        }
    },

    methods: {
        setLang(lang) {
            localStorage.setItem('lang', lang);
            this.lang = lang;
            document.title = globals.document_titles[this.lang];
        },

        emitLangChange() {
            const lang = this.lang === 'lat' ? 'cir' : 'lat';
            eventBus.$emit('langChange', { lang })
        },

        emitLangChangeFromUrl(history = false) {
            const lang = /[а-яА-ЯЁё]/.test(this.$route.params.slug.replaceAll('-', '')) ?
                'cir' : 'lat';

            if (this.lang !== lang) {
                eventBus.$emit('langChange', { lang, history })
            }
        },
    }
}