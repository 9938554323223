export default {
    methods: {
        resolveUrl(navItem) {
            if ('external_url' in navItem) {
                return navItem.external_url
            }

            if ('internal_url' in navItem) {
                return navItem.internal_url[this.lang];
            }

            return '#'
        },

        resolveTitle(navItem) {
            return navItem.title[this.lang];
        },

        resolveUrlTarget(navItem) {
            if ('external_url' in navItem) {
                return '_blank';
            }

            if ('internal_url' in navItem) {
                return '_self'
            }

            return null;
        },

        resolveComponent(navItem) {
            if ('internal_url' in navItem) {
                return 'router-link'
            }

            return 'a'
        }
    }
}