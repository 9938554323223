<template>
  <div id="header_special_links">
    <div v-for="(specialLink, key) in specialLinks[lang]" :key="key" class="hsl">
      <router-link :to="specialLink.url" :title="specialLink.caption">
        {{ specialLink.caption }}
      </router-link>
    </div>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
        LangMixin
    ],

    data() {
      return {
        specialLinks: []
      }
    },

    mounted() {
      this.requestSepcialLinks();
    },

    methods: {
      requestSepcialLinks() {
        fetch(`${this.$bhrt.api_uri}/amenu`)
          .then(response => response.json())
          .then(data => this.specialLinks = data.result)
          .catch(error => console.log(error));
      }
    }
  }
</script>